import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Contact from "../components/contact"
import ContactForm from "../components/contactform"

export default () => (
  <Layout>
    <SEO title="Contact" />
    <div>
      <h1>Contact</h1>
      <h2>Reach out, lets chat!</h2>
      <p>
        Feel free to contact me on any of the methods below or use the Contact Form.
      </p>
      <div>
        <Contact />
      </div>
      <div>
        <h3>Contact Form</h3>
        Send me a message on <strong>Slack</strong> using the contact form below.
        <ContactForm />
      </div>
    </div>
  </Layout>
)