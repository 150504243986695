import React from "react"
import contactStyles from "./contact.module.css"

import twitterLogo from "../images/Twitter_Logo_Blue.svg"
// import githubLogo from "../images/Octicons-mark-github.svg"
import linkedinLogo from "../images/LI-In-Bug.png"
import instagramLogo from "../images/Instagram_simple_icon.svg"
import emailLogo from "../images/Email_OneDrive_icon.svg"

export default () => {
  return (
    <div className={contactStyles.container}>
      <a href="mailto:reino.muhl@gmail.com">
        <div className={contactStyles.iconsContainer}>
          <p className={contactStyles.iconText}>Email</p>
          <img src={emailLogo} alt="Email" className={contactStyles.icon} />
        </div>
      </a>
      <a href="https://www.linkedin.com/in/reino-muhl-6826543a/">
        <div className={contactStyles.iconsContainer}>
          <p className={contactStyles.iconText}>LinkedIn</p>
          <img src={linkedinLogo} alt="LinkedIn" className={contactStyles.icon} id={contactStyles.linkedinicon} />
        </div>
      </a>
      <a href="https://twitter.com/quixoticZA">
        <div className={contactStyles.iconsContainer}>
          <p className={contactStyles.iconText}>Twitter</p>
          <img src={twitterLogo} alt="Twitter" className={contactStyles.icon} />
        </div>
      </a>
      <a href="https://www.instagram.com/staberindeza">
        <div className={contactStyles.iconsContainer}>
          <p className={contactStyles.iconText}>Instagram</p>
          <img src={instagramLogo} alt="Instagram" className={contactStyles.icon} />
        </div>
      </a>
    </div>
  )
}